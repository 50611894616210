import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoInternet = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
        gap: "10px",
      }}
    >
      <Typography sx={{ color: "text.main" }}>
        No internet connection
      </Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        {/* <Button
          onClick={() => {
            window.location.reload();
          }}
          sx={{
            backgroundColor: "origin.main",
            color: "white",
            ":hover": {
              backgroundColor: "origin.hover",
            },
          }}
        >
          try again
        </Button> */}
        <Button
          onClick={() => {
            navigate(-1);
            // window.location.reload();
          }}
          sx={{
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "origin.main",
            color: "origin.main",

            ":hover": {
              backgroundColor: "origin.main",
            },
          }}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default NoInternet;
