import axios from "axios";
import { _AuthApi } from "services/auth/auth.service";

export const _axios = axios.create();

_axios.interceptors.request.use(
  (config) => {
    const isAdmin = _AuthApi.getIsAdmin();
    const subdomain = _AuthApi.getSubDomain();

    config.baseURL =
      isAdmin === "false"
        ? `https://${subdomain}.mouhannadabdalrhem.online/api/v1`
        : "https://mouhannadabdalrhem.online/api/v1";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
