import { onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  messaging,
  registerServiceWorker,
  requestPermissions,
} from "../../../firebase";

const useFirebaseNotifications = () => {
  // const navigate = useNavigate();
  const { i18n } = useTranslation();
  // const setHasNotification = notificationStore(
  //   (state) => state.setHasNotification
  // );
  // const setCount = unreadNotifications((state) => state.setCount);
  // const count = unreadNotifications((state) => state.count);

  useEffect(() => {
    requestPermissions();
    registerServiceWorker();

    onMessage(messaging, (payload) => {
      // if (payload.data.screen !== "conversation") {
      //   setHasNotification(true);
      //   setCount(count + 1);
      // }
      toast.success({
        anchorOrigin: {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
        autoHideDuration: 5000,
        message: (
          <button
            // onClick={() => {
            //   if (payload.data?.screen !== "booking") {
            //     navigate("/chat/message");
            //   } else {
            //     navigate(`/booking/${payload.data?.id}`);
            //   }
            // }}
            className="flex flex-col max-w-[300px] justify-start items-start px-4 py-2"
          >
            <h3 className="text-xl text-bold">
              {i18n.language !== "en"
                ? payload.data?.title
                : payload.notification?.title}
            </h3>
            <p className="text-start line-clamp-3">
              {i18n.language !== "en"
                ? payload.data?.body
                : payload.notification?.body}
            </p>
          </button>
        ),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default useFirebaseNotifications;
